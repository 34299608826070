<template>
<div>
    HOMOLOGACIONES
</div>
</template>

<script>
export default {

    data() {
        return {

        }
    },

    mounted() {}
}
</script>
